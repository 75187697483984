import React, {useCallback, useState} from 'react';
import './Dashboard.css';
import {useNavigate} from 'react-router-dom'; // Import useHistory hook from React Router
import {useDropzone} from 'react-dropzone';
import {useRef} from 'react';
import {useEffect} from 'react';
import {FaUsers, FaChartLine, FaUserPlus, FaCalendarWeek, FaCalendarDay, FaEye, FaEyeSlash} from 'react-icons/fa';


function Dashboard() {
    const [inputText, setInputText] = useState('');
    const [paraText, setParaText] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState('en');
    const [translatedText, setTranslatedText] = useState('');
    const [parapharasedText, setParapharasedText] = useState('');
    const [ocrText, setOcrText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState(null);
    const [transcription, setTranscription] = useState('');
    const [activeSection, setActiveSection] = useState('home');
    const [isLoading, setIsLoading] = useState(false);
    const [bots, setBots] = useState([]);

    const [sect, setSect] = useState('');
    const handleSectChange = (event) => {
        setSect(event.target.value);
    };

    useEffect(() => {
        const fetchBots = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch('https://api.digitalaalim.in/admin/all-bots/', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    // Sort the bots by creation date, newest first
                    const sortedBots = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setBots(sortedBots);
                } else {
                    console.error('Failed to fetch bots');
                }
            } catch (error) {
                console.error('Error fetching bots:', error);
            }
        };
        fetchBots();
    }, []);

    const approveBot = async (botId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/approve-bot/${botId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("Bot Approved");
                setBots(bots.map(bot => {
                    if (bot.bot_id === botId) {
                        return {...bot, approved: true};
                    }
                    return bot;
                }));
            } else {
                alert("Failed to approve bot or bot already approved");
                const errorData = await response.json();
                console.error('Failed to approve bot:', errorData.detail || 'Unknown error');
            }
        } catch (error) {
            console.error('Error approving bot:', error);
        }
    };

    const suspendBot = async (botId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/disapprove_chatbot/${botId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("Bot Suspended");
                setBots(bots.map(bot => {
                    if (bot.bot_id === botId) {
                        return {...bot, approved: false};
                    }
                    return bot;
                }));
            } else {
                alert("Failed to suspend bot");
                const errorData = await response.json();
                console.error('Failed to suspend bot:', errorData.detail || 'Unknown error');
            }
        } catch (error) {
            console.error('Error suspending bot:', error);
        }
    };

    const [searchBotTerm, setBotSearchTerm] = useState('');

    const handleBotSearchChange = (e) => {
        setBotSearchTerm(e.target.value);
    };

    const filteredBots = bots.filter(bot =>
        bot.bot_name.toLowerCase().includes(searchBotTerm.toLowerCase()) ||
        bot.sect.toLowerCase().includes(searchBotTerm.toLowerCase())
    );


    const fileInputRef = useRef(null);
    const formData = new FormData();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [userStats, setUserStats] = useState({
        total_users: 0,
        new_users_last_month: 0,
        monthly_growth: '',
        new_users_last_week: 0,
        weekly_growth: '',
        new_users_last_day: 0
    });
    const [users, setUsers] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [qalamConversations, setqalamConversations] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // new user add
    const [newUserData, setNewUserData] = useState({
        name: '', email: '', password: '', role: 'user', // Default role is user
        sect: 'Hanafi' // Default sect
    });


    const handleNewUserInputChange = (event) => {
        const {name, value} = event.target;
        setNewUserData({
            ...newUserData, [name]: value
        });
    };


    const createNewUser = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'accessToken' is stored in local storage
        try {
            const response = await fetch('https://api.digitalaalim.in/admin/create-user/', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`,
                }, body: JSON.stringify(newUserData)
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message);
                setIsLoading(false);// Display success message
                // Optionally clear form or handle further logic
            } else {
                console.error('Failed to create user');
            }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };


    // Password Update
    const [passwordData, setPasswordData] = useState({
        name: '', email: '', password: '', confirmPassword: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [warning, setWarning] = useState('');

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setPasswordData({
            ...passwordData, [name]: value
        });
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown => !confirmPasswordShown);
    };

    const updatePassword = async (event) => {
        event.preventDefault();
        if (passwordData.password !== passwordData.confirmPassword) {
            setWarning('Passwords do not match!');
            return;
        }
        setWarning('');

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from local storage
        try {
            const response = await fetch('https://api.digitalaalim.in/user/update', {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`,
                }, body: JSON.stringify({
                    name: passwordData.name, email: passwordData.email, password: passwordData.password
                })
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message); // Show success message
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };


    const fetchChats = async () => {
        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/conversations/?page=${page}&page_size=${pageSize}`, {
                method: 'GET', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setConversations(data);
            } else {
                console.error('Failed to fetch chats');
                alert('Failed to fetch chats');
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
            alert('Error fetching chats: ' + error.message);
        }
    };


    const deleteChats = async (ChatId) => {
        const confirmed = window.confirm("Are you sure you want to delete this Chat?");
        if (!confirmed) {
            return; // If the user cancels, do nothing
        }

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/delete_conversation/${ChatId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("Chat deleted successfully");
                fetchChats();
            } else {
                alert("Failed to delete Chat");
                console.error('Failed to delete Chat:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting Chat:', error);
        }
    };


    const fetchQalamChats = async () => {
        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/qalam_conversations/?page=${page}&page_size=${pageSize}`, {
                method: 'GET', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setqalamConversations(data);
            } else {
                console.error('Failed to fetch chats');
                alert('Failed to fetch chats');
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
            alert('Error fetching chats: ' + error.message);
        }
    };


    const deleteQalamChats = async (ChatId) => {
        const confirmed = window.confirm("Are you sure you want to delete this Chat?");
        if (!confirmed) {
            return; // If the user cancels, do nothing
        }

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/delete_qalam_conversation/${ChatId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("Chat deleted successfully");
                fetchChats();
            } else {
                alert("Failed to delete Chat");
                console.error('Failed to delete Chat:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting Chat:', error);
        }
    };

    const StarChats = async (ChatId) => {
        const confirmed = window.confirm("Are you sure you want to update the starred status of this Chat?");
        if (!confirmed) {
            return; // If user cancels, do nothing
        }

        const accessToken = localStorage.getItem('accessToken');
        const chat = conversations.find(c => c.chat_id === ChatId);
        const newStarredStatus = !chat.starred; // Toggle the current status

        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/star_conversation/${ChatId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({starred: newStarredStatus}) // Send the new status
            });
            if (response.ok) {
                alert("Chat Status updated successfully");
                fetchChats(); // Re-fetch chats to update the UI
            } else {
                alert("Failed to update starred status of this Chat");
                console.error('Failed to update starred status of this Chat:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating starred status of this Chat:', error);
        }
    };


    const downloadCSV = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/export-chats-to-csv/?page=${page}&page_size=${pageSize}`, {
                method: 'GET', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `chats_${page}_${pageSize}.csv`); // Customize the filename as needed
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
                alert('Download started!');
            } else {
                console.error('Failed to download CSV');
                alert('Failed to download CSV');
            }
        } catch (error) {
            console.error('Error downloading CSV:', error);
            alert('Error occurred while downloading CSV');
        }
    };

    const fetchFeedbacks = async () => {
        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/view_feedback/?page=${page}&page_size=${pageSize}`, {
                method: 'GET', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setFeedbacks(data);
            } else {
                console.error('Failed to fetch feedbacks');
            }
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
    };

    const deleteFeedback = async (feedbackId) => {
        const confirmed = window.confirm("Are you sure you want to delete this feedback?");
        if (!confirmed) {
            return; // If the user cancels, do nothing
        }

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/delete_feedback/${feedbackId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("Feedback deleted successfully");
                setFeedbacks(feedbacks.filter(feedback => feedback.id !== feedbackId));
            } else {
                alert("Failed to delete feedback");
                console.error('Failed to delete feedback:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting feedback:', error);
        }
    };

    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        const fetchData = async () => {
            try {
                const response = await fetch('https://api.digitalaalim.in/admin/user-stats/', {
                    method: 'GET', // Make sure it's a GET request
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserStats(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchUsers = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch('https://api.digitalaalim.in/admin/get-users/', {
                    method: 'GET', headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data.map(user => ({
                        ...user,
                        isApproved: user.approval_status === "Approved" || user.approval_status === null,
                        approval_status: user.approval_status === null ? "Added by Admin" : user.approval_status
                    })));
                } else {
                    const errorData = await response.json();
                    console.error('Failed to fetch users:', errorData.detail || 'Unknown error');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };


        fetchData();
        fetchUsers();
        fetchChats();
        fetchFeedbacks();
        fetchQalamChats();

    }, [page, pageSize]);


    const approveUser = async (email) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.digitalaalim.in/admin/approve-user/?email=${email}`, {
                method: 'POST', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                alert("User Approved");
                setUsers(users.map(user => {
                    if (user.email === email) {
                        return {...user, isApproved: true, approval_status: "Approved"};
                    }
                    return user;
                }));
            } else {
                alert("Failed to approve user or user already approved");
                const errorData = await response.json();
                console.error('Failed to approve user:', errorData.detail || 'Unknown error');
            }
        } catch (error) {
            console.error('Error approving user:', error);
        }
    };


    const deleteUser = async (email) => {
        const confirmed = window.confirm("Are you sure you want to delete this user?");
        if (!confirmed) {
            return; // If the user cancels, do nothing
        }
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://api.digitalaalim.in/admin/delete-user/', {
                method: 'DELETE', headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`,
                }, body: JSON.stringify({email})
            });

            if (response.ok) {
                const data = await response.json();
                alert(data.detail);
                setUsers(users.filter(user => user.email !== email));
            } else {
                const errorData = await response.json();
                console.error('Failed to delete user:', errorData.detail || 'Unknown error');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleLimitChange = (event, index) => {
        const newLimit = parseInt(event.target.value, 10);
        setUsers(users.map((user, idx) => {
            if (idx === index) {
                return {...user, newLimit};
            }
            return user;
        }));
    };

    const handleSetQueryLimit = async (event, email) => {
        event.preventDefault();
        const user = users.find(user => user.email === email);
        if (!user) return;

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://api.digitalaalim.in/admin/set-query-limit/', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`,
                }, body: JSON.stringify({
                    email, new_limit: user.newLimit,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                setUsers(users.map(user => {
                    if (user.email === email) {
                        return {...user, query_limit: user.newLimit};
                    }
                    return user;
                }));
            } else {
                alert('Failed to update query limit: ' + (data.message || 'Unknown error'));
            }
        } catch (error) {
            console.error('Error setting new query limit:', error);
            alert('Error setting new query limit: ' + error.message);
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/');
    };

    const handleFileChange = (event) => {
        // This assumes that you are only interested in the first selected file
        // and that setSelectedFile is a function that updates the state for a single file
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };


    if (fileInputRef.current && fileInputRef.current.files) {
        Array.from(fileInputRef.current.files).forEach(file => {
            formData.append('files', file);
        });
    }

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);
    const {getRootProps, getInputProps} = useDropzone({onDrop});


    const handleFileDrop = (event) => {
        event.preventDefault();
        setSelectedFile(event.dataTransfer.files[0]);
    };

    const extractText = async () => {
        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        if (!accessToken) {
            throw new Error("No access token found. Please login or sign up.");
        }
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch('https://api.digitalaalim.in/ocr-urdu/', {
                method: 'POST', headers: {
                    // Append the Authorization header with the Bearer token
                    'Authorization': `Bearer ${accessToken}`,
                }, body: formData,
            });
            const data = await response.json();
            setOcrText(data.text);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleTranscribe = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

            if (!accessToken) {
                throw new Error("No access token found. Please login or sign up.");
            }
            const response = await fetch('https://api.digitalaalim.in/speech_to_text/', {
                method: 'POST', headers: {
                    // Append the Authorization header with the Bearer token
                    'Authorization': `Bearer ${accessToken}`,
                }, body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                setTranscription(result.text);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleTranslation = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

            if (!accessToken) {
                throw new Error("No access token found. Please login or sign up.");
            }
            // Constructing the query string
            const queryString = new URLSearchParams({
                text: inputText, source_language: sourceLanguage
            }).toString();

            const response = await fetch(`https://api.digitalaalim.in/translate-urdu/?${queryString}`, {
                method: 'POST', headers: {
                    // Append the Authorization header with the Bearer token
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTranslatedText(data.translated_text);
        } catch (error) {
            console.error('Error during translation', error);
        }
    };

    const handleParaphrase = async () => {
        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        if (!accessToken) {
            throw new Error("No access token found. Please login or sign up.");
        }
        try {
            const payload = {
                text: paraText, language: 'urdu'
            };

            const response = await fetch('https://api.digitalaalim.in/paraphrase-urdu/', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', // Append the Authorization header with the Bearer token
                    'Authorization': `Bearer ${accessToken}`,
                },

                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setParapharasedText(data.paraphrased_text);
        } catch (error) {
            console.error('Error during paraphrasing', error);
        }
    };

    const updateChatbot = async () => {
        const formData = new FormData();
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            alert("Access token is missing. Please login or sign up again.");
            return;
        }

        selectedFiles.forEach(file => {
            formData.append('files', file);
        });
        formData.append('sect', sect);

        try {
            const response = await fetch('https://api.digitalaalim.in/update_model/', {
                method: 'POST', headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }, body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            alert(result.message); // Using the message from the server which includes the sect.
            setSelectedFiles([]); // Clear selected files
            setSect(''); // Reset sect selection
        } catch (error) {
            alert('Failed to update chatbot. Please try again.');
        }
    };


    const handleUpdateFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const removeFile = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
    };

    // const initializeChatbot = async () => {
    //     try {
    //         // Retrieve the access token from local storage
    //         const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

    //         if (!accessToken) {
    //             throw new Error("No access token found. Please login or sign up.");
    //         }

    //         // Send a POST request to initialize the chatbot with Authorization header
    //         const initResponse = await fetch('http://127.0.0.1:8080/initialize_model/', {
    //             method: 'POST',
    //             headers: {
    //                 // Append the Authorization header with the Bearer token
    //                 'Authorization': `Bearer ${accessToken}`,
    //             },
    //             //body: formData,
    //         });


    //         if (!initResponse.ok) {
    //             if (initResponse.statusText === "Unauthorized") {
    //                 localStorage.removeItem('accessToken');
    //                 navigate('/');
    //                 throw new Error(`Session Expired. Login again`);
    //             }

    //         }


    //         // Parse the JSON response to get the success message
    //         const responseData = await initResponse.json();
    //         const successMessage = responseData.message;

    //         alert(successMessage); // Show the success message from the server


    //     } catch (error) {
    //         console.error('Error initializing the chatbot:', error.message);
    //         alert(`Chatbot initialization failed: ${error.message}`);
    //     }
    // };


    const [searchTerm, setSearchTerm] = React.useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm) ||
        user.role.toLowerCase().includes(searchTerm)
    );

    const handleTranslatedCopyText = () => {
        navigator.clipboard.writeText(translatedText);
    };
    const handleTranscriptionCopyText = () => {
        navigator.clipboard.writeText(transcription);
    };
    const handleParapharasedCopyText = () => {
        navigator.clipboard.writeText(parapharasedText);
    };
    const handleOcrCopyText = () => {
        navigator.clipboard.writeText(ocrText);
    };
    let navigate = useNavigate(); // Initialize the useNavigate hook

    const renderContent = () => {
        switch (activeSection) {
            case 'chatbot1':
                return (<div className="language-translator">
                    <h1 className="title"> Digital Aalim Language Translator </h1>
                    <p className="description">
                        From English, Arabic, and Persian (Farsi) to Urdu using AI Assistance
                    </p>
                    <div className="translator-container">
                        <div className="input-area">
                            <h3>Enter Your Text</h3>
                            <textarea
                                className="input-textarea"
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                                placeholder="Enter text to translate"
                            />
                        </div>
                        <div className="settings">
                            <h3>Select Source Language</h3>
                            <div className="language-selection">
                                <select
                                    className="language-select"
                                    value={sourceLanguage}
                                    onChange={(e) => setSourceLanguage(e.target.value)}>
                                    <option value="en">English</option>
                                    <option value="ar">Arabic</option>
                                    <option value="fa">Persian</option>
                                </select>
                            </div>

                            <div className="action-buttons">
                                <button className="translate-btn" onClick={handleTranslation}>Translate</button>
                            </div>
                        </div>

                        <div className="translated-text">
                            <h2>Translated Urdu Text</h2>
                            <textarea
                                className="output-textarea"
                                style={{fontFamily: 'Calibri'}}
                                value={translatedText}
                                readOnly
                            />
                            <button className="copy-btn" onClick={handleTranslatedCopyText}>Copy Text</button>
                        </div>
                    </div>
                </div>);

            // Add cases for other sections
            case 'chatbot2':
                return (<div className="tender-contract-comparison">
                    <h1 className="title"> Urdu Speech to Text </h1>
                    <p className="description">
                        Transform Urdu Speech into Actionable Insights: Upload your Urdu Audio Files for Advanced
                        AI-Powered Transcription and Analysis.
                    </p>

                    <div {...getRootProps()} className="drop-zone">
                        <input {...getInputProps()} />
                        <h3>How to Upload</h3>
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    {file && <p>File selected: {file.name}</p>}
                    <button className='tButton' onClick={handleTranscribe}>Transcribe</button>
                    <textarea value={transcription} readOnly/>
                    <button className='tButton' onClick={handleTranscriptionCopyText}>Copy Text</button>
                </div>);

            case 'chatbot3':
                return (<div className="chatbot-update-container">
                    <h1> Update Digital Aalim </h1>
                    <p>Enhance the intelligence of Digital Aalim with additional information.</p>
                    <div className="update-form">
                        <input type="file" multiple onChange={handleUpdateFileChange} className="file-input"/>
                        <select value={sect} onChange={handleSectChange} className="sect-dropdown">
                            <option value="">Select Sect</option>
                            <option value="Hanafi">Hanafi</option>
                            <option value="Jafari">Jafari</option>
                        </select>
                        <ul className="file-list">
                            {selectedFiles.map((file, index) => (<li key={index}>
                                {file.name}
                                <button onClick={() => removeFile(index)} className="delete-btn">X</button>
                            </li>))}
                        </ul>
                        <button onClick={updateChatbot} className="submit-btn">Update</button>
                    </div>
                </div>);

            case 'chatbot4':
                return (<div className="language-translator">
                    <h1 className="title">Urdu OCR</h1>
                    <p className="description">
                        Extract Urdu Text from old Printed Books
                    </p>

                    <div className="translator-container">
                        <div className="input-area">
                            <div className="file-drop-area" onDrop={handleFileDrop}
                                 onDragOver={(e) => e.preventDefault()}>
                                <input type="file" id="fileInput" className="file-input"
                                       onChange={handleFileChange}/>
                                <label htmlFor="fileInput" className="file-label">
                                    <i className="fa fa-upload"></i>
                                </label>
                            </div>
                        </div>
                        <div className="settings">

                            <div className="action-buttons">
                                <button className="translate-btn" onClick={extractText}>Extract Text</button>
                            </div>
                        </div>

                        <div className="translated-text">
                            <h2>Extracted Text</h2>
                            <textarea
                                className="output-textarea"
                                style={{fontFamily: 'Calibri'}}
                                value={ocrText}
                                readOnly
                            />
                            <button className="copy-btn" onClick={handleOcrCopyText}>Copy Text</button>
                        </div>
                    </div>
                </div>);


            case 'chatView':
                return (<div className="digital-aalim-chats">
                    <div className="settings-profile">
                        <h1 className="title">View User Chats</h1>
                        <div className="chat-controls">
                            <input
                                type="number"
                                value={page}
                                onChange={(e) => setPage(e.target.value)}
                                placeholder="Page"
                            />
                            <input
                                type="number"
                                value={pageSize}
                                onChange={(e) => setPageSize(e.target.value)}
                                placeholder="Page Size"
                            />
                            <button onClick={fetchChats}>Get Chats</button>
                            <button onClick={downloadCSV}>Download Chats</button>
                        </div>
                        <div className="table-container">
                            <table className="chats-table">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Timestamp</th>
                                    <th>Starred Status</th>
                                    <th>Delete Chat</th>
                                    <th>Update Starred Status</th>

                                </tr>
                                </thead>
                                <tbody>
                                {conversations.map((chat, index) => (<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{chat.email}</td>
                                    <td>{chat.question}</td>
                                    <td>{chat.answer}</td>
                                    <td>{new Date(chat.timestamp).toLocaleString()}</td>
                                    <td>{chat.starred}</td>
                                    <td>
                                        <button
                                            onClick={() => deleteChats(chat.chat_id)}
                                            className="delete-btn">
                                            Delete Chat
                                        </button>
                                    </td>
                                    <td>
                                        <label className="switch">
                                            <input type="checkbox" checked={chat.starred}
                                                   onChange={() => StarChats(chat.chat_id)}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </td>

                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>);

            case 'chatViewQalam':
                return (<div className="digital-aalim-chats">
                    <div className="settings-profile">
                        <h1 className="title">View Digital Qalam Chats</h1>
                        <div className="chat-controls">
                            <input
                                type="number"
                                value={page}
                                onChange={(e) => setPage(e.target.value)}
                                placeholder="Page"
                            />
                            <input
                                type="number"
                                value={pageSize}
                                onChange={(e) => setPageSize(e.target.value)}
                                placeholder="Page Size"
                            />
                            <button onClick={fetchQalamChats}>Get Chats</button>
                            {/*<button onClick={downloadCSV}>Download Chats</button>*/}
                        </div>
                        <table className="chats-table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Timestamp</th>
                                {/*<th>Starred Status</th>*/}
                                <th>Delete Chat</th>
                                {/*<th>Update Starred Status</th>*/}

                            </tr>
                            </thead>
                            <tbody>
                            {qalamConversations.map((chat, index) => (<tr key={index}>
                                <td>{index + 1}</td>
                                <td>{chat.email}</td>
                                <td>{chat.question}</td>
                                <td>{chat.answer}</td>
                                <td>{new Date(chat.timestamp).toLocaleString()}</td>
                                {/*<td>{chat.starred}</td>*/}
                                <td>
                                    <button
                                        onClick={() => deleteQalamChats(chat.chat_id)}
                                        className="delete-btn">
                                        Delete Chat
                                    </button>
                                </td>
                                {/*<td>*/}
                                {/*    <label className="switch">*/}
                                {/*        <input type="checkbox" checked={chat.starred}*/}
                                {/*               onChange={() => StarChats(chat.chat_id)}/>*/}
                                {/*        <span className="slider round"></span>*/}
                                {/*    </label>*/}
                                {/*</td>*/}

                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>);


            case 'feedback':
                return (
                    <div className="digital-aalim-chats">
                        <div className="settings-profile">
                            <h1 className="title">View User Feedbacks</h1>
                            <div className="chat-controls">
                                <input
                                    type="number"
                                    value={page}
                                    onChange={(e) => setPage(e.target.value)}
                                    placeholder="Page"
                                />
                                <input
                                    type="number"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(e.target.value)}
                                    placeholder="Page Size"
                                />
                                <button onClick={fetchFeedbacks}>Get Feedbacks</button>
                            </div>
                            <div className="table-container">
                                <table className="chats-table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Message</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {feedbacks.map((feedback, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{feedback.name}</td>
                                            <td>{feedback.email}</td>
                                            <td>{feedback.message}</td>
                                            <td>
                                                <button
                                                    onClick={() => deleteFeedback(feedback.id)}
                                                    className="delete-btn">
                                                    Delete Feedback
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );


            case 'userView':
                return (
                    <div>
                        <h1 className="title">Digital Aalim Users</h1>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search by Name, Email, or Role..."
                                className="search-input"
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Sect</th>
                                    <th>Role</th>
                                    <th>Authentication Method</th>
                                    <th>Created At</th>
                                    <th>Query Count</th>
                                    <th>Query Limit</th>
                                    <th>Set New Limit</th>
                                    <th>Delete User</th>
                                    <th>Approve User</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredUsers.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.sect}</td>
                                        <td>{user.role}</td>
                                        <td>{user.auth_method}</td>
                                        <td>{new Date(user.created_at).toLocaleString()}</td>
                                        <td>{user.query_count}</td>
                                        <td>{user.query_limit}</td>
                                        <td>
                                            <form className="set-limit-form"
                                                  onSubmit={(event) => handleSetQueryLimit(event, user.email)}>
                                                <input
                                                    type="number"
                                                    min="1"
                                                    required
                                                    value={user.newLimit || user.query_limit}
                                                    onChange={(e) => handleLimitChange(e, index)}
                                                    className="set-limit-input"
                                                />
                                                <button type="submit" className="set-limit-btn">Set</button>
                                            </form>
                                        </td>
                                        <td>
                                            <button onClick={() => deleteUser(user.email)} className="delete-btn">
                                                Delete
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => approveUser(user.email)}
                                                className="approve-btn"
                                                disabled={user.isApproved}>
                                                {user.approval_status}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );

            case 'maktabView':
                return (
                    <div>
                        <h1 className="title">Digital Aalim Maktab Chatbots</h1>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search by Name, Email, or Sect..."
                                className="search-input"
                                onChange={handleBotSearchChange}
                            />
                        </div>
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Icon</th>
                                    <th>Bot Name</th>
                                    <th>Email</th>
                                    <th>Sect</th>
                                    <th>Created At</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredBots.map((bot, index) => (
                                    <tr key={index}>
                                        <td>

                                            <img
                                                src={bot.icon_path ? `https://api.digitalaalim.in/${bot.icon_path}` : 'default_icon.png'}
                                                alt="Bot Icon"
                                                title={`Bot Name: ${bot.bot_name}\nDescription: ${bot.description}`} /* Tooltip content */
                                            />

                                        </td>
                                        <td>{bot.bot_name}</td>
                                        <td>{bot.email}</td>
                                        <td>{bot.sect}</td>
                                        <td>{new Date(bot.created_at).toLocaleString()}</td>
                                        <td>
                                            <button
                                                onClick={() => approveBot(bot.bot_id)}
                                                className="approve-btn"
                                                disabled={bot.approved}>
                                                {bot.approved ? "Approved" : "Approve"}
                                            </button>
                                            <button
                                                onClick={() => suspendBot(bot.bot_id)}
                                                className="approve-btn"
                                                disabled={!bot.approved}>
                                                Suspend Bot
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );


            case 'settings':

                return (<div className="setting-cards-container">
                        <div className="setting-card">
                            <h2 className="title">Add Users</h2>
                            <form onSubmit={createNewUser} className="create-user-form">
                                <input type="text" name="name" placeholder="Name" value={newUserData.name}
                                       onChange={handleNewUserInputChange} required/>
                                <input type="email" name="email" placeholder="Email" value={newUserData.email}
                                       onChange={handleNewUserInputChange} required/>
                                <div className="password-field">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="Password" value={newUserData.password}
                                           onChange={handleNewUserInputChange} required/>
                                    <i onClick={togglePasswordVisiblity}>{passwordShown ? <FaEye/> : <FaEyeSlash/>}</i>
                                </div>
                                <select name="role" value={newUserData.role} onChange={handleNewUserInputChange}
                                        required>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <select name="sect" value={newUserData.sect} onChange={handleNewUserInputChange}
                                        required>
                                    <option value="Hanafi">Hanafi</option>
                                    <option value="Jafari">Jafari</option>
                                </select>
                                <div className="signup-action-container">
                                    <button type="submit" className="signup-btn" disabled={isLoading}>Create</button>
                                    {isLoading && <div className="loader"></div>}
                                </div>
                            </form>
                        </div>
                        <div className="setting-card">
                            <h2 className="title">Update Admin/Users Passwords</h2>
                            <form onSubmit={updatePassword} className="create-user-form">
                                <input type="text" name="name" placeholder="Name" value={passwordData.name}
                                       onChange={handleInputChange}/>
                                <input type="email" name="email" placeholder="Email" value={passwordData.email}
                                       onChange={handleInputChange}/>
                                <div className="password-field">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="Password" value={passwordData.password}
                                           onChange={handleInputChange}/>
                                    <i onClick={togglePasswordVisiblity}>{passwordShown ? <FaEye/> : <FaEyeSlash/>}</i>
                                </div>
                                <div className="password-field">
                                    <input type={confirmPasswordShown ? 'text' : 'password'} name="confirmPassword"
                                           placeholder="Confirm Password" value={passwordData.confirmPassword}
                                           onChange={handleInputChange}/>
                                    <i onClick={toggleConfirmPasswordVisiblity}>{confirmPasswordShown ? <FaEye/> :
                                        <FaEyeSlash/>}</i>
                                </div>
                                {warning && <p className="warning">{warning}</p>}
                                <button type="submit" className="update-button">Update</button>
                            </form>
                        </div>
                    </div>

                );


            case 'MK':
                return (<div className="language-translator">
                    <h1 className="title">Urdu Paraphrasing</h1>
                    <p className="description">
                        Paraphrase Urdu with ease
                    </p>
                    <div className="translator-container">
                        <div className="input-area">
                            <h3>Enter Your Text</h3>
                            <textarea
                                className="input-textarea"
                                value={paraText}
                                style={{fontFamily: 'Calibri'}}
                                onChange={(e) => setParaText(e.target.value)}
                                placeholder="Enter Urdu text to paraphrase"
                            />
                        </div>
                        <div className="settings">
                            {/* <h3>Select Source Language</h3>
                                <div className="language-selection">
                                    <select
                                        className="language-select"
                                        value={sourceLanguage}
                                        onChange={(e) => setSourceLanguage(e.target.value)}>
                                        <option value="en">English</option>
                                        <option value="ar">Arabic</option>
                                        <option value="fa">Persian</option>
                                    </select>
                                </div> */}
                            <div className="action-buttons">
                                <button className="translate-btn" onClick={handleParaphrase}>Paraphrase</button>
                            </div>
                        </div>
                        <div className="translated-text">
                            <h2>Output Text</h2>
                            <textarea
                                className="output-textarea"
                                style={{fontFamily: 'Calibri'}}
                                value={parapharasedText}
                                readOnly/>
                            <button className="copy-btn" onClick={handleParapharasedCopyText}>Copy Text</button>
                        </div>
                    </div>
                </div>);

            default:
                return (<>
                    <div>
                        <h1>Digital Aalim Content Management Dashboard</h1>
                        <p>Your Ultimate Solution for Islamic Text Retrieval and Chatbot Training.
                            Embark on a journey of Islamic knowledge with the Digital Aalim Dashboard, a
                            ground-breaking platform tailored to streamline
                            the retrieval of Islamic text from any source. Designed specifically for Islamic
                            content, this innovative tool harnesses
                            the power of AI to extract text from audio recordings, OCR scans, and websites,
                            empowering users to access invaluable Islamic
                            teachings with unparalleled ease.</p>
                    </div>
                    <div className="card-container"
                         style={{display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center'}}>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaUsers size={50}/>
                            <h3>Total Users</h3>
                            <p>{userStats.total_users}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaUserPlus size={50}/>
                            <h3>New Users Last Month</h3>
                            <p>{userStats.new_users_last_month}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaChartLine size={50}/>
                            <h3>Monthly Growth</h3>
                            <p>{userStats.monthly_growth}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaCalendarWeek size={50}/>
                            <h3>New Users Last Week</h3>
                            <p>{userStats.new_users_last_week}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaChartLine size={50}/>
                            <h3>Weekly Growth</h3>
                            <p>{userStats.weekly_growth}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaCalendarDay size={50}/>
                            <h3>New Users Last Day</h3>
                            <p>{userStats.new_users_last_day}</p>
                        </div>
                    </div>
                </>);
        }
    };


    return (<div className="dashboard-container">
        <aside className="sidebar">
            <h2>Menu</h2>
            <button onClick={() => setActiveSection('home')} className={activeSection === 'home' ? 'active' : ''}>
                Home
            </button>
            <button onClick={() => setActiveSection('chatbot1')}
                    className={activeSection === 'chatbot1' ? 'active' : ''}>
                Urdu Translation
            </button>
            <button onClick={() => setActiveSection('chatbot2')}
                    className={activeSection === 'chatbot2' ? 'active' : ''}>
                Urdu Speech to Text
            </button>

            <button onClick={() => setActiveSection('chatbot4')}
                    className={activeSection === 'chatbot4' ? 'active' : ''}>
                Urdu OCR
            </button>
            <button onClick={() => setActiveSection('MK')} className={activeSection === 'MK' ? 'active' : ''}>
                Urdu Paraphrasing
            </button>
            {/* <button onClick={() => setActiveSection('setting')} className={activeSection === 'setting' ? 'active' : ''}>
                    Settings
                </button> */}
            <button onClick={() => setActiveSection('chatbot3')}
                    className={activeSection === 'chatbot3' ? 'active' : ''}>
                Update Digital Aalim
            </button>
            <button onClick={() => setActiveSection('chatView')}
                    className={activeSection === 'chatView' ? 'active' : ''}>
                View Chats
            </button>
            <button onClick={() => setActiveSection('chatViewQalam')}
                    className={activeSection === 'chatViewQalam' ? 'active' : ''}>
                View Qalam Chats
            </button>
            <button onClick={() => setActiveSection('userView')}
                    className={activeSection === 'userView' ? 'active' : ''}>
                View Users
            </button>
            <button onClick={() => setActiveSection('maktabView')}
                    className={activeSection === 'maktabView' ? 'active' : ''}>
                View Maktab chatbots
            </button>
            <button onClick={() => setActiveSection('feedback')}
                    className={activeSection === 'feedback' ? 'active' : ''}>
                View Feedbacks
            </button>
            <button onClick={() => setActiveSection('settings')}
                    className={activeSection === 'settings' ? 'active' : ''}>
                Settings
            </button>
            <button onClick={handleLogout}>Logout</button>
        </aside>
        <main className="main-content">
            {renderContent()}
        </main>
    </div>);
}

export default Dashboard;
